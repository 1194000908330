import React from "react";


const PrivacyPolicy = () => {
  return (
    <>
   <div className="term_bg">
        <div className="term_overlay">
          <h4 className="f-49 text-white">Privacy Policy</h4>
        </div>
      </div>

      <div className="container mt-5">
        <h2 className="uuse text-left">
           Last updated:
          <span className="text-black fs-5"> June 30, 2023</span>
        </h2>
        <p className="mt-5 text-justify">
          This Privacy Policy describes our policies and procedures on the
          collection, use and disclosure of your information when you use the
          Service and tells you about your privacy rights and how the law
          protects you.
        </p>
        <p className="text-justify">
          We use your Personal data to provide and improve the Service. By using
          the Service, you agree to the collection and use of information in
          accordance with this Privacy Policy.
        </p>
        <h2 className="uuse mt-4">Interpretation and Definitions</h2>
        <h2 className="uusee mt-4 ">Interpretation</h2>
        <p className="text-justify">
          The words have meanings defined under the following conditions. The
          following definitions shall have the same meaning regardless of
          whether they appear in singular or in plural.
        </p>
        <h2 className="uusee mt-4">Definitions</h2>
        <p className="text-justify">For the purposes of this Privacy Policy:</p>
        <p className="text-justify">
          <b>Account</b> means a unique account created for You to access our
          Service or parts of our Service.
        </p>
        <p className="text-justify">
          <b>Affiliate</b> means an entity that controls, is controlled by or
          is under common control with a party, where "control" means ownership
          of 50% or more of the shares, equity interest or other securities
          entitled to vote for election of directors or other managing
          authority.
        </p>
        <p className="text-justify">
          <b>Application</b> means the software program provided by the Company
          downloaded by You on any electronic device, named AllCanFarm.
        </p>
        <p className="text-justify">
          <b>Company</b> (referred to as either "the Company", "We", "Us" or
          "Our" in this Agreement) refers to Avigna Consulting Pte Ltd.
        </p>
        <p className="text-justify">
          <b>Country </b> refers to: India
        </p>
        <p className="text-justify">
          <b>Device</b> means any device that can access the Service such as a
          computer, a cell phone or a digital tablet.
        </p>
        <p className="text-justify">
          <b>Personal Data </b> is any information that relates to an identified
          or identifiable individual.
        </p>
        <p className="text-justify">
          <b>Service</b> refers to the Application.
        </p>
        <p className="text-justify">
          <b>Service Provider </b> means any natural or legal person who
          processes the data on behalf of the Company. It refers to third-party
          companies or individuals employed by the Company to facilitate the
          Service, to provide the Service on behalf of the Company, to perform
          services related to the Service or to assist the Company in analysing
          how the Service is used.
        </p>
        <p className="text-justify">
          <b>Usage Data </b> refers to data collected automatically, either
          generated by the use of the Service or from the Service infrastructure
          itself (for example, the duration of a page visit).
        </p>
        <p className="text-justify">
          <b>You</b> mean the individual accessing or using the Service, or the
          company, or other legal entity on behalf of which such individual is
          accessing or using the Service, as applicable.
        </p>
        <h3 className="uuse mt-4">Collecting and Using Your Personal Data</h3>
        <h3 className="uusee mt-4">Types of Data Collected</h3>
        <h3 className="uusee mt-4">Personal Data</h3>
        <p className="text-justify">
          While using our service, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you. Personally identifiable information may include, but is
          not limited to:
        </p>
        <p>• Email address</p>
        <p>• First name and last name</p>
        <p>• Phone number</p>
        <p>• Address, State, Province, ZIP/Postal code, City</p>
        <p>• Usage Data</p>
        <h2 className="uuse mt-4">Usage Data</h2>
        <p>Usage Data is collected automatically when using the Service.</p>
        <p className="text-justify">
          Usage Data may include information such as your device's Internet
          Protocol address (e.g., IP address), browser type, browser version,
          the pages of our service that you visit, the time and date of your
          visit, the time spent on those pages, unique device identifiers and
          other diagnostic data.
        </p>
        <p className="text-justify">
          When You access the Service by or through a mobile device, we may
          collect certain information automatically, including, but not limited
          to, the type of mobile device You use, your mobile device unique ID,
          the IP address of your mobile device, your mobile operating system,
          the type of mobile Internet browser You use, unique device identifiers
          and other diagnostic data.
        </p>
        <p className="text-justify">
          We may also collect information that Your browser sends whenever you
          visit our service or when you access the service by or through a
          mobile device.
        </p>
        <h3 className="uuse mt-4">
          Information Collected while Using the Application
        </h3>
        <p className="text-justify">
          While using Our Application, in order to provide features of Our
          Application, we may collect, with your prior permission:
        </p>
        <p>• Information regarding your location</p>
        <p className="text-justify">
          • Pictures and other information from your Device's camera and photo
          library
        </p>
        <p className="text-justify">
          • AllCanFarm collects location data to enable location specific display
          of relevant product in the application. Based on the location of the
          customer relevant products which are available in that radius range
          only will be shown to the customer for purchase even when the
          application is closed or not in use.{" "}
        </p>
        <p className="text-justify">
          • AllCanFarm collects location data to enable available product details
          even when the app is closed or not in use and it is also used to
          support advertising.
        </p>
        <p>
          • We are committed to complying with applicable data protection laws.
        </p>
        <p className="text-justify">
          We use this information to provide features of our service, to improve
          and customize our service. The information may be uploaded to the
          Company's servers and/or a Service Provider's server or it may be
          simply stored on your device.
        </p>
        <p className="text-justify">
          You can enable or disable access to this information at any time,
          through your device settings.
        </p>
        <h3 className="uuse mt-4">Use of Your Personal Data</h3>
        <p>The Company may use Personal Data for the following purposes:</p>
        <p className="text-justify">
          <b>To provide and maintain our Service, </b>including to monitor the
          usage of our Service.
        </p>
        <p className="text-justify">
          <b>To manage Your Account: </b>to manage your registration as a user
          of the Service. The Personal Data You provide can give you access to
          different functionalities of the Service that are available to you as
          a registered user.
        </p>
        <p className="text-justify">
          <b>For the performance of a contract: </b>the development, compliance
          and undertaking of the purchase contract for the products, items or
          services you have purchased or of any other contract with us through
          the Service.
        </p>
        <p className="text-justify">
          <b>To contact You:</b>To contact you by email, telephone calls, SMS,
          or other equivalent forms of electronic communication, such as a
          mobile application's push notifications regarding updates or
          informative communications related to the functionalities, products or
          contracted services, including the security updates, when necessary or
          reasonable for their implementation.
        </p>
        <p className="text-justify">
          <b>To provide You </b>with news, special offers and general
          information about other goods, services and events which we offer that
          are similar to those that you have already purchased or enquired about
          unless you have opted not to receive such information.
        </p>
        <p className="text-justify">
          <b>To manage Your requests: </b>To attend and manage your requests to
          us.
        </p>
        <p className="text-justify">
          <b>For business transfers: </b>We may use your information to evaluate
          or conduct a merger, divestiture, restructuring, reorganization,
          dissolution, or other sale or transfer of some or all of our assets,
          whether as a going concern or as part of bankruptcy, liquidation, or
          similar proceeding, in which Personal Data held by us about our
          service users is among the assets transferred.
        </p>
        <p className="text-justify">
          <b>For other purposes</b>We may use your information for other
          purposes, such as data analysis, identifying usage trends, determining
          the effectiveness of our promotional campaigns and to evaluate and
          improve our service, products, services, marketing and your
          experience.
        </p>
        <p className="text-justify">
          We may share Your personal information in the following situations:
        </p>
        <p className="text-justify">
          <b>With Service Providers: </b>We may share Your personal information
          with Service Providers to monitor and analyse the use of our service,
          to contact You.
        </p>
        <p className="text-justify">
          <b>For business transfers: </b>We may share or transfer your personal
          information in connection with, or during negotiations of, any merger,
          sale of company assets, financing, or acquisition of all or a portion
          of Our business to another company.
        </p>
        <p className="text-justify">
          <b>With Affiliates</b>We may share your information with our
          affiliates, in which case we will require those affiliates to honour
          this Privacy Policy. Affiliates include Our parent company and any
          other subsidiaries, joint venture partners or other companies that we
          control or that are under common control with us.
        </p>
        <p className="text-justify">
          <b>With business partners: </b>: We may share Your information with
          our business partners to offer You certain products, services or
          promotions.
        </p>
        <p className="text-justify">
          <b>With other users: </b>when you share personal information or
          otherwise interact in the public areas with other users, such
          information may be viewed by all users and may be publicly distributed
          outside.
        </p>
        <p className="text-justify">
          <b>With Your consent: </b>: We may disclose your personal information
          for any other purpose with your consent.
        </p>
        <h3 className="uuse mt-4">Retention of Your Personal Data</h3>
        <p className="text-justify">
          The Company will retain your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
        </p>
        <p className="text-justify">
          The Company will also retain Usage Data for internal analysis
          purposes. Usage Data is generally retained for a shorter period of
          time, except when this data is used to strengthen the security or to
          improve the functionality of our service, or we are legally obligated
          to retain this data for longer time periods.
        </p>
        <h3 className="uuse mt-4">Transfer of Your Personal Data</h3>
        <p className="text-justify">
          Your information, including Personal Data, is processed at the
          Company's operating offices and in any other places where the parties
          involved in the processing are located. It means that this information
          may be transferred to — and maintained on — computers located outside
          of your state, province, country or other governmental jurisdiction
          where the data protection laws may differ than those from Your
          jurisdiction.
        </p>
        <p className="text-justify">
          Your consent to this Privacy Policy followed by your submission of
          such information represents your agreement to that transfer.
        </p>
        <p>
          The Company will take all steps reasonably necessary to ensure that
          your data is treated securely and in accordance with this Privacy
          Policy and no transfer of your personal data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of your data and other personal information.
        </p>
        <h3 className="uuse mt-4">Delete Your Personal Data</h3>
        <p className="text-justify">
          You have the right to delete or request that We assist in deleting the
          Personal Data that we have collected about you.
        </p>
        <p className="text-justify">
          Our Service may give you the ability to delete certain information
          about you from within the Service.
        </p>
        <p className="text-justify">
          You may update, amend, or delete your information at any time by
          signing in to your Account, if you have one, and visiting the account
          settings section that allows you to manage your personal information.
          You may also contact us to request access to, correct, or delete any
          personal information that you have provided to us.
        </p>
        <p className="text-justify">
          Please note, however, that we may need to retain certain information
          when we have a legal obligation or lawful basis to do so.
        </p>
        <h3 className="uuse mt-4">Disclosure of Your Personal Data</h3>
        <h3 className="uusee mt-4">Business Transactions</h3>
        <p className="text-justify">
          If the Company is involved in a merger, acquisition or asset sale,
          your Personal Data may be transferred. We will provide notice before
          your Personal Data is transferred and becomes subject to a different
          Privacy Policy.
        </p>
        <h3 className="uusee mt-4">Law enforcement</h3>
        <p className="text-justify">
          Under certain circumstances, the Company may be required to disclose
          Your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g., a court or a government agency).
        </p>
        <h3 className="uusee mt-4">Other legal requirements</h3>
        <p className="text-justify">
          The Company may disclose Your Personal Data in the good faith belief
          that such action is necessary to:
        </p>
        <p>• Comply with a legal obligation</p>
        <p>• Protect and defend the rights or property of the Company</p>
        <p className="text-justify">
          • Prevent or investigate possible wrongdoing in connection with the
          Service
        </p>
        <p className="text-justify">
          • Protect the personal safety of Users of the Service or the public
        </p>
        <p>• Protect against legal liability</p>

        <h3 className="uuse mt-4">Security of Your Personal Data</h3>
        <p className="text-justify">
          The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially
          acceptable means to protect Your Personal Data, we cannot guarantee
          its absolute security.
        </p>
        <h3 className="uuse mt-4">Children's Privacy</h3>
        <p className="text-justify">
          Our Service does not address anyone under the age of 18. We do not
          knowingly collect personally identifiable information from anyone
          under the age of 18. If You are a parent or guardian and you are aware
          that Your child has provided Us with Personal Data, please contact us.
          If We become aware that We have collected Personal Data from anyone
          under the age of 18 without verification of parental consent, we take
          steps to remove that information from our servers.
        </p>
        <p className="text-justify">
          If we need to rely on consent as a legal basis for processing your
          information and your country requires consent from a parent, we may
          require Your parent's consent before we collect and use that
          information.
        </p>

        <h3 className="uuse mt-4">Links to Other Websites</h3>
        <p className="text-justify">
          Our Service may contain links to other websites that are not operated
          by Us. If You click on a third-party link, you will be directed to
          that third party's site. We strongly advise You to review the Privacy
          Policy of every site You visit.
        </p>
        <p className="text-justify">
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third-party sites or services.
        </p>

        <h3 className="uuse mt-4">Changes to this Privacy Policy</h3>
        <p className="text-justify">
          We may update Our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page.
        </p>
        <p className="text-justify">
          We will let You know via email and/or a prominent notice on Our
          Service, prior to the change becoming effective and update the "Last
          updated" date at the top of this Privacy Policy.
        </p>
        <p className="text-justify">
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>
        <div className="mb-5"></div>
      </div>
      <div>
        
      </div>
    </>
  );
};
export default PrivacyPolicy;
